import React from "react";
import Image from "next/image";
import Spartan from "public/assets/img/slider/spartan.webp";

import { DetailsBody, Container, Row, Coluna, DetailsItem } from "./styles";

import { detailsNumbers } from "./constants";
import Link from "next/link";

export const Details = () => {
  return (
    <DetailsBody>
      <Coluna>
        <Row>
          <Container>
            <div className="title">
              <h2>
                Alcance seu elo em
                <br /> <span className="text-border">tempo recorde</span>
              </h2>
              <hr className="bar" />
            </div>
            <div className="details">
              {detailsNumbers.map((detail, index) => (
                <DetailsItem key={index}>
                  <div className="detailContainer">
                    <div className="title">
                      <detail.icon size={40} color="#fff" />
                      <p>{detail.title}</p>
                      <h3>{detail.value}</h3>
                    </div>
                  </div>
                </DetailsItem>
              ))}
            </div>
          </Container>
        </Row>
      </Coluna>
      <Coluna className="col-sec">
        <Image
          src={Spartan}
          alt="imagem sobre valorant"
          className="imageDetails"
          width={924}
          height={614}
        />
      </Coluna>
    </DetailsBody>
  );
};
