import { FaUsers } from "react-icons/fa6";
import { IoMedalSharp } from "react-icons/io5"

export const detailsNumbers = [
  {
    title: "Serviços Finalizados",
    value:'+11.534',
    icon:IoMedalSharp
  },
  {
    title: "Boosters Online",
    value:'37',
    icon:FaUsers
  }
 
];
